import { useEffect } from "react";
import Breadcrumbs from "../../../layouts/Breadcrumbs";
import image01 from "../../../../assets/images/servicos/consultoria-de-vendas-online.webp";
import image01thumb from "../../../../assets/images/servicos/consultoria-de-vendas-online-thumb.webp";
import image02 from "../../../../assets/images/servicos/presenca-online.webp";
import image02thumb from "../../../../assets/images/servicos/presenca-online-thumb.webp";
import image03 from "../../../../assets/images/servicos/trafego-organico.webp";
import image03thumb from "../../../../assets/images/servicos/trafego-organico-thumb.webp";
import image04 from "../../../../assets/images/servicos/leads-qualificados.webp";
import image05 from "../../../../assets/images/servicos/aumento-de-vendas.webp";
import image05thumb from "../../../../assets/images/servicos/aumento-de-vendas-thumb.webp";

const ConsultoriaVendasOnline = () => {
  useEffect(() => {
    document.title =
      "Consultoria de Vendas: Minha empresa precisa vender mais?";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Para um verdadeiro sucesso na mídia, temos que pensar em estratégias de vendas online e Offline, se bem alinhadas trazem a qualidade e o sucesso do empreendimento."
      );
    }
  }, []);

  const breadcrumbsItens = [
    {
      name: "Serviços",
      alias: "/servicos",
    },
    {
      name: "Estratégias",
      alias: "/estrategias",
    },
    {
      name: "Consultoria de vendas online",
      alias: "/consultoria-de-vendas-online",
    },
  ];

  return (
    <>
      <Breadcrumbs
        title={"Consultoria de vendas online"}
        items={breadcrumbsItens}
      />
      <section id="servicesInsidePage">
        <div className="relative bg_secundary section_padding_clip">
          <div className="container">
            <h2 className="title_divider">
              Consultoria de vendas online: Minha empresa precisa?
            </h2>

            <picture>
              <source media="(max-width: 768px)" srcSet={image01thumb} />
              <img
                width="1170"
                height="632"
                src={image01}
                alt="Mulher frustrada em frente ao computador"
                className="mb-30 mt-30"
              />
            </picture>

            <p>
              Hoje em dia é fundamental que as empresas possuam uma boa
              visibilidade no mundo virtual, principalmente empresas que fazem a
              consultoria de vendas online.
            </p>

            <p>
              Para um verdadeiro sucesso na mídia, temos que pensar em
              estratégias que vão desde a criação do e-commerce, na mensagem que
              ele vai passar aos seus visitantes, até a conversão do cliente.
              Essas estratégias se bem alinhadas trazem a qualidade e o sucesso
              do empreendimento online.
            </p>

            <p>
              Porém, nem sempre somos aptos a desenvolver estratégias tão
              assertivas que tragam resultados concretos e duradouros. E é nesse
              momento que entra o serviço do consultor de vendas online.
            </p>

            <p>
              Esse consultor é um especialista que gera o aumento das vendas
              online. O papel do profissional é analisar os pontos e
              necessidades da sua empresa e criar as melhores saídas para
              alavancar as vendas e corrigir os erros que impedem que sua
              empresa vende cada vez mais.
            </p>

            <p>
              Separamos alguns pontos para que você se informe mais das
              vantagens que a consultoria pode te oferecer:
            </p>

            <ul className="list">
              <li>Presença Online</li>
              <li>Tráfego Orgânico</li>
              <li>Leads Qualificados</li>
              <li>Aumento de Vendas</li>
            </ul>

            <p>
              Lembrando que a Agência Boa Imagem tem disponibilidade para
              trabalhar com vários segmentos dentro da área de publicidade e com
              esse serviço de consultoria de vendas online, não seria diferente.
            </p>

            <h3>Presença online</h3>
            <picture>
              <source media="(max-width: 768px)" srcSet={image02thumb} />
              <img
                className="mb-30 mt-30"
                width="1170"
                height="682"
                src={image02}
                alt="Grupo de jovens olhando seus aparelhos eletrônicos"
                loading="lazy"
              />
            </picture>
            <p>
              Ter presença online é um ponto fundamental. Pois essa é a forma
              que a sua empresa será encontrada pelo público, pelo seu site ou
              redes sociais.
            </p>

            <p>
              A construção de uma boa presença é baseada nas buscas que são
              feitas pelas pessoas, sejam buscas pelo nome da empresa, pelos
              serviços ou produtos. Para fazermos uma marca no meio digital é
              importante estarmos dispostos a nos adaptarmos ao que o público
              busca e ao que ele realmente precisa.
            </p>

            <h3>Tráfego orgânico</h3>

            <picture>
              <source media="(max-width: 768px)" srcSet={image03thumb} />
              <img
                className="mb-30 mt-30"
                width="690"
                height="676"
                src={image03}
                alt="Resultado de pesquisa no Google"
              />
            </picture>
            <p>
              Esse tipo de tráfego é o que possibilita a sua empresa receber
              visitas através de busca online, sem custos. Imaginamos que sua
              empresa necessita de atenção em vários segmentos, investir em tudo
              pode não ser tão vantajoso e pode acabar extrapolando o limite do
              que você pretende gastar.
            </p>

            <p>
              Nesse caso, você precisa possuir uma boa estratégia na parte de
              marketing de conteúdo. Esse conceito se estiver ligado diretamente
              com o tráfego orgânico traz um bom resultado nas buscas, sem
              custos.
            </p>

            <h3>Leads qualificados</h3>

            <picture>
              <source media="(max-width: 768px)" srcSet={image04} />
              <img
                className="mb-30 mt-30"
                width="648"
                height="649"
                src={image04}
                alt="Funil de vendas"
              />
            </picture>
            <p>
              Para selecionar os leads qualificados é necessário direcionar a
              comunicação online para eles. Devemos lembrar que atrás de cada
              computador existe uma pessoa real e quando ela é atingida de forma
              concisa as vendas online se tornam mais propensas a conversão.
            </p>

            <p>
              O principal é dar valor as pessoas que decidiram seguir a sua
              empresa, ficando sempre de olho nos produtos e novidades que você
              pode oferecer. A consultoria precisa gerar valor para as pessoas
              no meio online.
            </p>

            <h3>Aumento das vendas</h3>

            <picture>
              <source media="(max-width: 768px)" srcSet={image05thumb} />
              <img
                className="mb-30 mt-30"
                width="1170"
                height="780"
                src={image05}
                alt="Gráfico de em ascensão"
              />
            </picture>
            <p>
              Essa consultoria deve gerar o aumento das vendas e para isso é
              simples, basta buscar um profissional da área. O profissional
              poderá te guiar do início atraindo seu público até a meta final
              que é a conversão na compra. Sendo assim, investir em uma empresa
              que possua colaboradores que entendam do assunto, pode gerar boas
              estratégias e isso vai engrandecer o seu negócio.
            </p>

            <p>
              <b>Nós te ajudamos!</b>
            </p>

            <p>
              A Agência Boa Imagem, tem como foco ajudar pequenas, médias e
              grandes empresas a ampliarem significativamente as vendas. Temos
              como meta capacitar a equipe do contratante com a autonomia
              suficiente para manter e ampliar os resultados. A consultoria de
              vendas online é um processo temporário e enriquecedor para o seu
              negócio.
            </p>

            <p>
              <b>
                Se você deseja diminuir seus custos e vender mais na plataforma
                online, entre em contato conosco que teremos o prazer em te
                ajudar!
              </b>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConsultoriaVendasOnline;
